import { configureStore } from '@reduxjs/toolkit'
import { DashboardSlice } from './reducer/dashboardSlice';
import { usermgmtSlice } from './reducer/usermanagementSlice';


export const Store = configureStore({
    reducer: {
     dashdata:DashboardSlice.reducer,
     usermgt:usermgmtSlice.reducer
    },
});
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CloseFeedback, getfeedbackdetails } from "../redux/actions/adminActions";
import { Modal,Button } from "react-bootstrap";
import { toast } from "react-toastify";


export default function FeedbackInfo() {

 const [details,setdetails]=useState("");
 const [show, setshow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    const getdata = async() => {
      dispatch(getfeedbackdetails({id:id})).then((res)=> {
        console.log(res);
        if(res?.payload?.data?.status === 200){
          setdetails(res?.payload?.data?.data)

        }
      }).catch((err) => {
        console.log(err,"error occur");
      })
    }
    getdata();
  },[id])


  const handleTicket = () => {
    dispatch(CloseFeedback({feedbackId:id,type:1})).then((res) => {
      console.log(res);
      if(res?.payload?.data?.status === 200){
        toast.success(res?.payload?.data?.message)
          setshow(false);
          
      }
    }).catch((err)=>{
      console.log(err,"Occur");
    })
  }
    
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Feedback Info</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Feedback">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure.Caption>
                        <div className="customer-form-new border-none mb-4">
                          <div className="">
                            <h3>Details</h3>
                            <Form>
                            <Row>
                              <Col lg={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Profile name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Type Here"
                                    value={details?.name || "NA"}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={12} className="customer-form-inner pt-0 pb-0 mb-0">
                                <Form.Group className="mb-3">
                                  <Form.Label>Message from User</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    Rows="4"
                                    name="message"
                                    placeholder="Type Here"
                                    value={details?.message || "NA"}
                                  />
                                </Form.Group>
                              </Col>

                              {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                            </Row>
                            </Form>

                          </div>
                        </div>
                      </Figure.Caption>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Admin Action</h2>
                        <div className="ticket-btn" onClick={() => setshow(true)}>
                       <Link>Close Ticket</Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Close this Ticket
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleTicket}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  dowanloadOpen,
  getOpenFeedback,
  getOpenlist,
} from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/pagination";
import * as XLSX from "xlsx";

export default function OpenFeedback() {
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [feedbackData, setfeedbackdata] = useState([]);
  const [pagi, setpagi] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getdata = async () => {
      dispatch(
        getOpenFeedback({ page: page, search: search, limit: limit, type: 0 })
      )
        .then((response) => {
          console.log(response);
          if (response?.payload?.data?.status == 200) {
            setfeedbackdata(response?.payload?.data?.data?.feedbackData);
            setpagi(response?.payload?.data?.data);
          }
        })
        .catch((err) => {
          console.log(err, "error occur");
        });
    };
    getdata();
  }, [search, limit, page, flag]);

  const handleFeeedback = (id) => {
    navigate(`/FeedbackInfo/${id}`);
  };

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadOpen({ type: 0 }));
      console.log(response);

      const allData = response?.payload?.data?.data?.feedbackData;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2> Open Feedback</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Profile Action</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </Col> */}
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Feedback">All</Link>
              </li>
              <li>
                <Link to="/openfeedback" className="active-tab">
                  Open
                </Link>
              </li>{" "}
              <li>
                <Link to="/closefeedback">Closed</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Profile name</th>
                <th>Sent on</th>
                <th>Message</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {feedbackData?.map((details, index) => {
                const serialNumber =
                  ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFeeedback(details?._id)}
                      >
                        <b>{details?.name || "NA"}</b>
                      </p>
                    </td>
                    <td>
                      <p>
                        {details?.createdAt
                          ? new Date(details.createdAt)
                              .toLocaleDateString("en-GB")
                              .slice(0, 10)
                          : "N/A"}
                      </p>
                    </td>
                    <td>{details?.message || "NA"}</td>
                    <td>{details?.type === 0 ? "Open" : "Close"}</td>
                    {/* <td>
                  <Button className="account-btn common-colr-btn">Open</Button>{" "}
                </td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}

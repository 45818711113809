import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Orders({ dogs, cats, rodents }) {
  const data = {
    labels: [`Dogs ${dogs}`, `Cats ${cats}`, `Rodents ${rodents}`],
    datasets: [
      {
        label: "# of Votes",
        data: [50, 30, 20],
        backgroundColor: ["#4e6693", "#4e6693", "#4e6693"],

        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Doughnut className="gender-chart" data={data} />
    </>
  );
}

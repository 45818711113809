import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../redux/actions/adminAuth";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span style={{ color: "red" }}>Invalid Email Address</span>)
    .required(<span style={{ color: "red" }}>Required</span>),
  password: Yup.string()
    .min(6, <span style={{ color: "red" }}>Password too short</span>)
    .required(<span style={{ color: "red" }}>Required</span>),
});

export default function Login() {
  const dispatch = useDispatch();




  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginSchema}
            onSubmit={async (values) => {
              console.log(values);
              try {
                let data = await dispatch(login(values));
                console.log(data);
                if (data?.payload?.status === 200) {
                  toast.success(data?.payload?.message);
                  setTimeout(() => {
                    toast.dismiss();
                    window.location.href = "/Dashboard";
                  }, 1500);
                } else {
                  toast.error(data?.payload?.message);
                }
              } catch (error) {
                sessionStorage.clear();
                console.log(error);
              }
            }}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form>
                <Col lg={12} className="m-auto">
                  <div className="login-cmn-box">
                    <div className="login-box-inner-wrap">
                      <h2 className="text-center">Log in</h2>
                      <Form>
                        <div className="form-set">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Type Here"
                              name="email"
                            onChange={handleChange}
                            value={values?.email}
                          />
                          <ErrorMessage name="email" component="div" />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Type Here"
                              name="password"
                            onChange={handleChange}
                            value={values?.password}
                          />
                          <ErrorMessage name="password" component="div" />
                          </Form.Group>
                        </div>
                        <button
                          // to="/Dashboard"
                          variant="primary"
                          type="submit"
                          className="submit"
                          onClick={handleSubmit}
                        >
                          Log In
                        </button>
                        <Link to="/Forgot-Password" className="forgot">
                          Forgot Password?
                        </Link>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </div>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/adminAPI";

// ========= forgot-Passsword ==============

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (details) => {
    const data = await AdminAPI.post(`/auth/forgetpassword`, details);
    console.log(data);
    return data;
  }
);

//  ========== verify Otp ===========

export const verifyotp = createAsyncThunk("verifyotp", async (details) => {
  const data = await AdminAPI.post(`/auth/verifyotp`, details);
  console.log(data);
  return data;
});

// ================== Reset Password =======================

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await AdminAPI.post(`/auth/resetPassword`, details);
    console.log(data);
    return data;
  }
);

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/auth/changePassword`, details);
    console.log(data);
    return data;
  }
);

// ===================== Dashboard Api ====================

export const getDashboard = createAsyncThunk(
  "getDashboard",
  async (details) => {
    const data = await AdminAPI.get(`/dashboard`, details);
    console.log(data);
    return data;
  }
);

// =================== usermanagement ==================

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// dowanload xlsv for all usermanagent all users

export const dowanloadusermgmtXlsv = createAsyncThunk(
  "dowanloadusermgmtXlsv",
  async () => {
    const data = await AdminAPI.get(`/userManagement`);
    console.log(data);
    return data;
  }
);

// get customer profile by id

export const getCustomerdetails = createAsyncThunk(
  "getCustomerdetails",
  async (details) => {
    const data = await AdminAPI.get(`/userProfile?userId=${details?.id}`);
    console.log(data);
    return data;
  }
);

// =========== Susbend account =============

export const suspendAccount = createAsyncThunk(
  "suspendAccount",
  async (details) => {
    const data = await AdminAPI.put(`/banUnbanUser`, details);
    console.log(data);
    return data;
  }
);

// =========== Delete account =============

export const DeleteAccount = createAsyncThunk(
  "suspendAccount",
  async (details) => {
    const data = await AdminAPI.patch(`/deleteAccount?userId=${details?.id}`);
    console.log(data);
    return data;
  }
);

// ======== BanOrUnban user Profile Action =================

export const BanOrUnBan = createAsyncThunk("BanOrUnBan", async (details) => {
  const data = await AdminAPI.put(`/banUnbanUser`, details);
  console.log(data);
  return data;
});

// ========= Get banuser Listing ============

export const getBannedUser = createAsyncThunk(
  "getBannedUser",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// ============ Dowanload xlsv BanedUser=========

export const dowanloadBanXlsv = createAsyncThunk(
  "dowanloadBanXlsv",
  async (details) => {
    const data = await AdminAPI.get(`/userManagement`, details);
    console.log(data);
    return data;
  }
);

// ================ get Active USers Data =====================

export const getActiveUsers = createAsyncThunk(
  "getActiveUsers",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}&type=${details?.type}`;

    if (details.search) {
      url += `&name=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// ============ Dowanload xlsv Activeuser =========

export const dowanloadActiveXlsv = createAsyncThunk(
  "dowanloadActiveXlsv",
  async (details) => {
    const data = await AdminAPI.get(`/userManagement`, details);
    console.log(data);
    return data;
  }
);

// =========== get all pets listing ===============

export const GetAllPets = createAsyncThunk("GetAllPets", async (details) => {
  const data = await AdminAPI.get(
    `/petManagement?page=${details?.page}&limit=${details?.limit}&userId=${details?.id}`
  );
  return data;
});

// ============== Remove Pet ===========

export const RemovePets = createAsyncThunk("RemovePets", async (details) => {
  const data = await AdminAPI.patch(`/removePet?petId=${details?.id}`);
  return data;
});

// =============  Get pet details ===============

export const getpetsdetails = createAsyncThunk(
  "getpetsdetails",
  async (details) => {
    const data = await AdminAPI.get(`/petProfile?userId=${details?.id}`);
    console.log(data);
    return data;
  }
);

// ================ get user activity ===================

export const getActivity = createAsyncThunk("getActivity", async (details) => {
  const { data } = await AdminAPI.get(`/getActivities?user_id=${details?.id}`);
  return data;
});

// ================ get PetProfile ===================

export const getPetProfile = createAsyncThunk(
  "getPetProfile",
  async (details) => {
    const { data } = await AdminAPI.get(`/petProfile?petId=${details?.id}`);
    return data;
  }
);

// ========= get feedback listing =============

export const getFeedback = createAsyncThunk("getFeedback", async (details) => {
  const data = await AdminAPI.get(
    `/getFeedbacks?page=${details?.page}&limit=${details?.limit}&name=${details?.search}`
  );
  return data;
});

// ============== get feedback details =============

export const getfeedbackdetails = createAsyncThunk(
  "getfeedbackdetails",
  async (details) => {
    const data = await AdminAPI.get(
      `/getFeedbackById?feedbackId=${details?.id}`
    );
    return data;
  }
);

// ============== Dowanload ALl Fedback =============

export const downloadfeedbacks = createAsyncThunk(
  "downloadfeedbacks",
  async (details) => {
    const data = await AdminAPI.get(`/getFeedbacks`);
    return data;
  }
);

// ========= get feedback listing for open =============

export const getOpenFeedback = createAsyncThunk(
  "getOpenFeedback",
  async (details) => {
    const data = await AdminAPI.get(
      `/getFeedbacks?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&type=${details?.type}`
    );
    return data;
  }
);

// ============== Dowanload Open Fedback details =============

export const dowanloadOpen = createAsyncThunk(
  "dowanloadOpen",
  async (details) => {
    const data = await AdminAPI.get(`/getFeedbacks`, details);
    return data;
  }
);

// ============== Close feedback ticket =============

export const CloseFeedback = createAsyncThunk(
  "CloseFeedback",
  async (details) => {
    const data = await AdminAPI.put(`/updateTicket`, details);
    return data;
  }
);

// ========= get feedback listing for Close =============

export const getClosed = createAsyncThunk("getClosed", async (details) => {
  const data = await AdminAPI.get(
    `/getFeedbacks?page=${details?.page}&limit=${details?.limit}&name=${details?.search}&type=${details?.type}`
  );
  return data;
});

// ============== Dowanload closed Fedback details =============

export const dowanloadClosed = createAsyncThunk(
  "dowanloadClosed",
  async (details) => {
    const data = await AdminAPI.get(`/getFeedbacks`, details);
    return data;
  }
);

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { GetAllPets, RemovePets } from "../redux/actions/adminActions";
import { toast } from "react-toastify";
import Pagination from "../Components/Layout/pagination";
import PetNav from "../Components/PetNav";

export default function AllPets() {
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [page, setpage] = useState(1);
  const [Petdata, setPetsData] = useState([]);
  const [pagi, setpagi] = useState("");
  const [showd, setshowd] = useState(false);
  const [Id, setId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(GetAllPets({ id: id, page: page, limit: limit })).then((res) => {
      console.log(res);
      if (res?.payload?.status === 200) {
        setPetsData(res?.payload?.data?.data?.users);
        setpagi(res?.payload?.data?.data);
      }
    });
  }, [page, limit, flag]);

  const handleDelete = async () => {
    const data = await dispatch(RemovePets({ id: Id }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshowd(false);
      setflag(!flag);
    }
  };

  const handleProfile = (id) => {
    navigate(`/PetProfile/${id}`);
  };

  const CustomerProfile = () => {
    navigate(`/CustomerProfile/${id}`);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>All Pets</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <PetNav  id={id}/>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pagi?.currentPage - 1) * limit} -{" "}
                {Math.min(pagi?.currentPage * limit, pagi?.totalCount)} of{" "}
                {pagi?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Listed on</th>
                <th>Name</th>
                <th>Breed</th>
                <th>Gender</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Petdata?.length > 0 ? (
                Petdata.map((details, index) => {
                  const serialNumber =
                    ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>
                        {details?.updatedAt
                          ? new Date(details.updatedAt)
                              .toISOString()
                              .slice(0, 10)
                              .split("-")
                              .reverse()
                              .join("/")
                          : ""}
                      </td>
                      <td>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => handleProfile(details?._id)}
                        >
                          <b>{details?.pet_name ? details?.pet_name : "N/A"}</b>
                        </p>
                      </td>
                      <td>{details?.breed ? details?.breed : "N/A"}</td>
                      <td>{details?.gender === 0 ? "Male" : "Female"}</td>
                      <td className="user-img">
                        <img
                          src={
                            details?.pet_image
                              ? `https://petcare-api.bosselt.com/${details?.pet_image}`
                              : "Not Found"
                          }
                          alt={details?.pet_name}
                        />
                      </td>
                      <td>
                        <Button
                          className="account-btn common-colr-btn"
                          onClick={() => {
                            setshowd(true);
                            setId(details?._id);
                          }}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="table-pagi">
        <Pagination
          totalstuff={pagi?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Remove this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { DeleteAccount, getActivity, getCustomerdetails, suspendAccount } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/Profile-icon.png"
import { toast } from "react-toastify";
import { Modal,Button } from "react-bootstrap";
import PetNav from "../Components/PetNav";

export default function CustomerProfile() {
  
  const [UserDeatils, setUserDetails] = useState("");
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  const [ActivityDetails, setActivityDetails] = useState([]);

  console.log(UserDeatils);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(
        getCustomerdetails({id:id})
      );
      console.log(data);
      if (data?.payload?.status === 200) {
        setUserDetails(data?.payload?.data?.data);
      }
    };
    getuserdata();
  }, [id]);

  useEffect(() => {
    const getactivitydata = async()=>{
      const data = await dispatch(getActivity({id:id}));
      console.log(data);
      if (data?.payload?.status === 200) {
        setActivityDetails(data?.payload?.data)
      }
    }
    getactivitydata()
  },[id])


  const handleSuspend = async () => {
    const data = await dispatch(
     suspendAccount({userId:id, type:0})
    );
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/UserManagement");
    }
  };

  const handleDelete = async () => {
    const data = await dispatch(DeleteAccount({id:id}));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setshow(false);
      navigate("/UserManagement");
    }
  };

  const handlePetsnavihate = () => {
    navigate(`/AllPets/${id}`)
  }



  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>

            <PetNav  id={id}/>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row className="">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <div className="d-flex">
                        <span className="add-icon-verify">
                          <img
                            width={171}
                            height={180}
                            alt="171x180"
                            src={
                              UserDeatils?.profile_image
                                ? `https://petcare-api.bosselt.com/${UserDeatils?.profile_image}`
                                : Logo
                            }
                          />
                        </span>
                        <div className="figure-caption">
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Profile info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>User name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.full_name}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      placeholder="Type Here"
                                      value={UserDeatils?.email}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>No of Pets Listed</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={UserDeatils?.totalPets}
                                    />
                                  </Form.Group>
                                </Col>
                                {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                        {ActivityDetails?.map((details) => {
                           return(    
                          <ul>
                            <li>
                            <h6>{details?.title}</h6>
                              <p>{details?.createdAt
                                      ? new Date(details?.createdAt)
                                          .toISOString()
                                          .slice(0, 10)
                                          .replace(/-/g, "/")
                                      : "N/A"}</p>
                            </li>
                          </ul>
                           ) 
                          })}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6} className=" mb-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div  class="dlt-ac-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setshowd(true)} >
                          <a >Delete Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className=" mb-4">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => setshow(true)} >
                          <a >Suspend Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

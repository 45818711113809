import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Users({activeUsers,InactiveUsers}) {
 console.log(activeUsers,InactiveUsers);
 const data = {
  labels: [`Active Users ${activeUsers}%`, `InActive Users ${InactiveUsers}%`],
  datasets: [
    {
      label: "# of Votes",
      data: [activeUsers,InactiveUsers],
      backgroundColor: ["#4e6693","#2e3d58"],

      borderWidth: 1,
    },
  ],
};


  return (
    <>
  <Doughnut className="gender-chart" data={data} />
  </>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure } from "react-bootstrap";
import { BsTextarea } from "react-icons/bs";
import { getPetProfile } from "../redux/actions/adminActions";
import { useDispatch } from "react-redux";
import Logo from "../Assets/Images/user.png";

export default function PetProfile() {
  const [PetDetails, setPetDetails] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getuserdata = async () => {
      const data = await dispatch(getPetProfile({ id: id }));
      console.log(data);
      if (data?.payload?.status === 200) {
        setPetDetails(data?.payload?.data);
      }
    };
    getuserdata();
  }, [id]);

  const handleBackToAllPets = () => {
    navigate(`/Allpets/${PetDetails?.owner_name}`);
  };

  console.log(PetDetails?.notes);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Pet Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left" onClick={handleBackToAllPets}>
                <Link>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0 gap-3"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4 ">
        <Row className="mb-4">
          <Col lg={12}>
            <Row className="mb-4">
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex inner-img">
                        <Figure.Image
                          alt="171x180"
                          src={
                            PetDetails?.pet_image
                              ? `https://petcare-api.bosselt.com/${PetDetails?.pet_image}`
                              : Logo
                          }
                        />
                        <Figure.Caption>
                          <div className="customer-form-new border-none mb-4">
                            <div className="">
                              <h3>Pet info</h3>
                              <Row>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Pet name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.pet_name
                                          ? PetDetails?.pet_name
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.type
                                          ? PetDetails?.type
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Breed</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.breed
                                          ? PetDetails?.breed
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.gender === 0
                                          ? "Male"
                                          : "Female"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Date of Birth</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.updatedAt
                                          ? new Date(PetDetails.updatedAt)
                                              .toISOString()
                                              .slice(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Weight</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.weight
                                          ? PetDetails?.weight
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Height</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.height
                                          ? PetDetails?.height
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.color
                                          ? PetDetails?.color
                                          : "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Neutured/Spreyed</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.is_sprayed === 0
                                          ? "yes"
                                          : "No"
                                      }
                                    />
                                  </Form.Group>
                                </Col>{" "}
                                <Col lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Microchip number</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here"
                                      value={
                                        PetDetails?.microchip_number || "N/A"
                                      }
                                    />
                                  </Form.Group>
                                </Col>{" "}
                                <Col
                                  lg={12}
                                  className="customer-form-inner pt-0 pb-0 mb-0"
                                >
                                  <Form.Group className="mb-3">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      Rows="4"
                                      value={PetDetails?.notes}
                                      placeholder="Type Here"
                                    />
                                  </Form.Group>
                                </Col>
                                {/* <div class="d-flex justify-content-end mt-3 col-lg-12">
                                  <button type="button" class="add-btn">
                                    Save
                                  </button>
                                </div> */}
                              </Row>
                            </div>
                          </div>
                        </Figure.Caption>
                      </Figure>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-2">Document</h2>
                        {PetDetails?.docData?.map((Docs) => {
                          return (
                            <Row>
                              <Col lg={6} className="mb-3   ">
                                <p>{Docs?.doc_title}</p>
                                <img
                                  className="profile-add-img"
                                  src={
                                    Docs?.doc
                                      ? `https://petcare-api.bosselt.com/${Docs?.doc}`
                                      : "Not Found"
                                  }
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Contacts</h2>
                        <Row>
                          <Col lg={12} className="mb-3">
                            <div className="box-shadow-box">
                              <div className="contact-detail-info">
                                <div className="d-flex justify-content-between">
                                  <h3>Mike Johnson</h3>
                                  <div className="veterinarian-text">
                                    Veterinarian
                                  </div>
                                </div>
                                <div className="contact-detail">
                                  <div className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="14"
                                      viewBox="0 0 16 14"
                                      fill="none"
                                    >
                                      <rect
                                        x="0.535156"
                                        width="14.939"
                                        height="14"
                                        rx="3"
                                        fill="#E4E7F0"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.1214 9.93093C11.8579 10.6813 10.8256 11.0557 10.088 10.9933C9.08069 10.908 7.98458 10.4072 7.15254 9.85573C5.92952 9.04509 4.78391 7.79127 4.11634 6.46065C3.64455 5.52042 3.53869 4.3642 4.24041 3.50716C4.49992 3.19037 4.78106 3.02131 5.2022 3.00158C5.78725 2.97491 5.8692 3.2885 6.07009 3.77702C6.21977 4.14234 6.41953 4.51512 6.53107 4.89378C6.73994 5.60042 6.00977 5.62975 5.91757 6.20733C5.86066 6.57158 6.33132 7.0601 6.54416 7.31982C6.95443 7.82566 7.45691 8.25952 8.0284 8.60138C8.3528 8.79284 8.87524 9.13789 9.2463 8.9475C9.81769 8.65418 9.76419 7.75127 10.5627 8.05686C10.9764 8.21473 11.377 8.44245 11.772 8.64191C12.3827 8.94963 12.3542 9.26855 12.1214 9.93093C12.2956 9.43601 11.9473 10.4258 12.1214 9.93093Z"
                                        fill="black"
                                      />
                                    </svg>
                                    888 22-111-22-65
                                  </div>
                                </div>
                                <div className="contact-detail">
                                  <div className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="14"
                                      viewBox="0 0 16 14"
                                      fill="none"
                                    >
                                      <rect
                                        x="0.535156"
                                        width="14.939"
                                        height="14"
                                        rx="3"
                                        fill="#E4E7F0"
                                      />
                                      <path
                                        d="M8.00615 3C6.23481 3 4.80493 4.34 4.80493 6C4.80493 8 8.00615 11 8.00615 11C8.00615 11 11.2074 8 11.2074 6C11.2074 4.34 9.77749 3 8.00615 3ZM8.00615 4C8.57216 4 9.11499 4.21071 9.51522 4.58579C9.91545 4.96086 10.1403 5.46957 10.1403 6C10.1403 7.11 9.1906 8 8.00615 8C7.44014 8 6.89731 7.78929 6.49708 7.41421C6.09685 7.03914 5.872 6.53043 5.872 6C5.872 5.46957 6.09685 4.96086 6.49708 4.58579C6.89731 4.21071 7.44014 4 8.00615 4Z"
                                        fill="black"
                                      />
                                    </svg>
                                    Viale S. Giovanni, 29, 39034 Dobbiaco BZ
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Routines</h2>
                        {PetDetails?.RoutineData?.map((Routine) => {
                          return (
                            <Row>
                              <Col lg={12} className="mb-3">
                                <div className="box-shadow-box">
                                  <div className="routines-detail-info">
                                    <div className="vaccination-date-line">
                                      <div className="vaccination-text">
                                        {Routine?.category === 0
                                          ? "Vaccination"
                                          : Routine?.category === 1
                                          ? "Hygiene"
                                          : "Medication"}
                                      </div>
                                      <div className="vaccination-date">
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <rect
                                              x="2.5"
                                              y="3.5"
                                              width="11"
                                              height="10"
                                              rx="2.5"
                                              stroke="#4E6693"
                                            />
                                            <path
                                              d="M3 7.5L13 7.5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M6 11H10"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M5.5 2L5.5 5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M10.5 2L10.5 5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                          {Routine?.createdAt
                                            ? new Date(Routine.createdAt)
                                                .toLocaleDateString("en-GB")
                                                .slice(0, 10)
                                            : "N/A"}
                                        </div>
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M3.85355 3.10355C4.04882 2.90829 4.04882 2.59171 3.85355 2.39645C3.65829 2.20118 3.34171 2.20118 3.14645 2.39645L1.64645 3.89645C1.45118 4.09171 1.45118 4.40829 1.64645 4.60355C1.84171 4.79882 2.15829 4.79882 2.35355 4.60355L3.85355 3.10355ZM12.25 8.75C12.25 11.0972 10.3472 13 8 13C5.65279 13 3.75 11.0972 3.75 8.75C3.75 6.40279 5.65279 4.5 8 4.5C10.3472 4.5 12.25 6.40279 12.25 8.75ZM13.25 8.75C13.25 11.6495 10.8995 14 8 14C5.10051 14 2.75 11.6495 2.75 8.75C2.75 5.85051 5.10051 3.5 8 3.5C10.8995 3.5 13.25 5.85051 13.25 8.75ZM12.1464 2.39645C11.9512 2.59171 11.9512 2.90829 12.1464 3.10355L13.6464 4.60355C13.8417 4.79882 14.1583 4.79882 14.3536 4.60355C14.5488 4.40829 14.5488 4.09171 14.3536 3.89645L12.8536 2.39645C12.6583 2.20118 12.3417 2.20118 12.1464 2.39645ZM10.3536 7.35355C10.5488 7.15829 10.5488 6.84171 10.3536 6.64645C10.1583 6.45118 9.84171 6.45118 9.64645 6.64645L7.5 8.79289L6.35355 7.64645C6.15829 7.45118 5.84171 7.45118 5.64645 7.64645C5.45118 7.84171 5.45118 8.15829 5.64645 8.35355L6.96967 9.67678C7.26256 9.96967 7.73744 9.96967 8.03033 9.67678L10.3536 7.35355Z"
                                              fill="#4E6693"
                                            />
                                          </svg>
                                          <p>
                                            {Routine?.time &&
                                              new Date(
                                                Routine.time
                                              ).toLocaleTimeString("en-US", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                hour12: true,
                                              })}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <h3>{Routine?.title}</h3>
                                    <p>😱 {Routine?.notes}</p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4  h-100">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Observations</h2>
                        {PetDetails?.observationData?.map((details) => {
                          return (
                            <Row>
                              <Col lg={12} className="mb-3">
                                <div className="box-shadow-box">
                                  <div className="Observations-detail-info">
                                    <div className="vaccination-date-line add-b-line">
                                      <div className="observations-text">
                                        {details?.category === 0
                                          ? "Vaccination"
                                          : details?.category === 1
                                          ? "Hygiene"
                                          : "Medication"}
                                      </div>
                                      <div className="vaccination-date">
                                        <div className="">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <rect
                                              x="2.5"
                                              y="3.5"
                                              width="11"
                                              height="10"
                                              rx="2.5"
                                              stroke="#4E6693"
                                            />
                                            <path
                                              d="M3 7.5L13 7.5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M6 11H10"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M5.5 2L5.5 5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M10.5 2L10.5 5"
                                              stroke="#4E6693"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                          {details?.createdAt
                                            ? new Date(details.createdAt)
                                                .toLocaleDateString("en-GB")
                                                .slice(0, 10)
                                            : "N/A"}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="medical-records-details">
                                      <div className="medical-records-img">
                                        <img
                                          src={require("../Assets/Images/pet-img.png")}
                                        />
                                        <p>{details?.title}</p>
                                      </div>
                                      <p>{details?.notes}</p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const PetNav = (props) => {
  const { id } = props;

  const Activenav = {
    "Overview": `/CustomerProfile/${id}`,
    "ALL PETS": `/AllPets/${id}`,
  };

  return (
    <Col
      lg={8}
      md={8}
      className="d-flex justify-content-end align-items-center p-0 gap-3"
    >
      {/* <div className="cmn-btn" >
        <Link>Overview</Link>
      </div>
      <div className="cmn-btn">
        <Link to="/AllPets" className="active-btn-select">
          All Pets
        </Link>
      </div> */}

      {Object.entries(Activenav).map((value) => {
        console.log(value,"jkdasfdfnladskfdfas");
        return (
          <div className="cmn-btn">
            <Link
              to={value[1]}
              className={`${
                value[1] === window.location.pathname && "active-btn-select"
              }`}
            >
              {value[0]}
            </Link>
          </div>
        );
      })}
    </Col>
  );
};

export default PetNav;

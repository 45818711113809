import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import CustomerProfile from "./pages/CustomerProfile";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import OrderManagement from "./pages/OrderManagement";
import ManageChat from "./pages/ManageChat";
import PetProfile from "./pages/PetProfile";
import Feedback from "./pages/Feedback";
import FeedbackInfo from "./pages/FeedbackInfo";
import AllPets from "./pages/AllPets";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import TemporaryBanned from "./pages/TemporaryBanned";
import Active from "./pages/Active";
import OpenFeedback from "./pages/OpenFeedback";
import CloseFeedback from "./pages/Closefeedback";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Forgot-Password" element={<ForgotPassword />}/>
        <Route path="/Otp" element={<LoginOtp />} />
        <Route path="/Resetpassword" element={<ResetPassword />} />

        <Route element={<ProtectedRoutes />}>

          <Route path="/temporaryBan" element={<TemporaryBanned />} />
          <Route path="/UserActive" element={<Active />} />
          <Route path="/ManageChat" element={<ManageChat />} />
          <Route path="/OrderManagement" element={<OrderManagement />}/>        
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
          <Route path="/Supportchat" element={<Supportchat />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />                 
          <Route path="/PetProfile/:id" element={<PetProfile />} />  
          <Route path="/Feedback" element={<Feedback />} />
          <Route path="/FeedbackInfo/:id" element={<FeedbackInfo />} />
          <Route path="/openfeedback" element={<OpenFeedback />} />
          <Route path="/closefeedback" element={<CloseFeedback />} />
          <Route path="/AllPets/:id" element={<AllPets />} />
          </Route>
                
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { BanOrUnBan, dowanloadusermgmtXlsv, usermanagement } from "../redux/actions/adminActions";
import Pagination from "../Components/Layout/pagination";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";



export default function UserManagement() {
  const userdata = useSelector((state) => state.usermgt.alldata);
  console.log(userdata);
  const [type, settype] = useState(0);
  const [page, setpage] = useState(1);

  const [limit, setlimit] = useState(10);
  const [date, setdate] = useState("");
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);



  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: search,
    };
    if (date) {
      searchData.date = date;
    }

    dispatch(usermanagement(searchData));
  }, [page, limit, search, flag]);

  const handleProfile = (id) => {
    navigate(`/CustomerProfile/${id}`)
  }

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(dowanloadusermgmtXlsv());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  },[dispatch,type])


  const handleBanUnban = (id,value) => {
    dispatch(BanOrUnBan({userId:id,type:value})).then((res) => {
      console.log(res);
      if (res?.payload?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setflag(!flag)
      }

    })
  }


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>User Management</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Profile Action</option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select> */}
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link  onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="/temporaryBan">Temporary Banned</Link>
              </li>
              <li>
                <Link to="/UserActive">Active</Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                <div className="show-results">
                  {" "}
                  Showing {1 + (userdata?.data?.data?.currentPage - 1) * limit} -{" "}
                {Math.min(
                  userdata?.data?.data?.currentPage * limit,
                  userdata?.data?.data?.totalCount
                )}{" "}
                of {userdata?.data?.data?.totalCount} results
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>User Name</th>

                <th>Email</th>
                <th>No. of Pets</th>
                <th>Status</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
              {userdata?.data?.data?.users?.map((data, index) => {
                const serialNumber =
                ((page == "1" ? 1 : page) - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p style={{ cursor:"pointer"}} onClick={() => handleProfile(data?._id)}>
                        <b>{data?.full_name ? data?.full_name : "N/A"}</b>
                      </p >
                    </td>
                    <td>{data?.email ? data?.email : "N/A"}</td>
                    <td>{data?.totalPets ? data?.totalPets : "N/A"}</td>
                    <td>{data?.is_active === 1 ? "Active" : "Banned"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select aria-label="Default select example" 
                        onChange={(e) => {
                          handleBanUnban(data?._id,e.target.value)
                        }}
                        value={data?.is_active}>
                          <option value="0">Temporary Banned</option>
                          <option value="1">Active</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="table-pagi">
        <Pagination
          totalstuff={userdata?.data?.data?.totalCount}
          limit={limit}
          setpages={setpage}
        />
      </div>
    </Layout>
  );
}
